import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ToastContainer,
  cssTransition
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalConfirmDeleteContainerConnected, ModalConfirmContainerConnected } from '../../containers/ModalContainer';
import { setRouteHistory } from '../../helpers/functions/route';
import { HomeScreen } from '../../screens/HomeScreen';
import { YearWarrantyScreen } from '../../screens/YearWarrantyScreen';
import { LoginScreen } from '../../screens/LoginScreen';
import { getAuthSuccess, getLastOTPSuccessDay, getUserAuth, handleCheckMaintenance, setUserAuthRedux } from '../../helpers/functions/auth';
import { ROUTE_PATH } from '../../helpers/constants/route';
// import { SalesTransactionIndexesScreen } from '../../screens/SalesTransactionIndexesScreen/SalesTransactionIndexesScreen';
import { SalesOrdersScreen } from '../../screens/SalesOrdersScreen/SalesOrdersScreen';
import { SalesHistoryScreen } from '../../screens/SalesHistoryScreen/SalesHistoryScreen';
import { SpecialWarrantyScreen } from '../../screens/SpecialWarrantyScreen/SpecialWarrantyScreen';
// import { InventoryIndexesScreen } from '../../screens/InventoryIndexesScreen/InventoryIndexesScreen';
import { InventoryScreen } from '../../screens/InventoryScreen/InventoryScreen';
import { CoilTransferScreen } from '../../screens/CoilTransferScreen/CoilTransferScreen';
import { AuthorisedDealerAndRollFormerManageScreen } from '../../screens/AuthorisedDealerAndRollFormerManageScreen/AuthorisedDealerAndRollFormerManageScreen';
import { CoilTransactionScreenConnected } from '../../screens/CoilTransactionScreen/CoilTransactionScreen';
import { CoilTransactionManageScreenConnected } from '../../screens/CoilTransactionManageScreen/CoilTransactionManageScreen';
import { CoilTransferHistoryScreen } from '../../screens/CoilTransferHistoryScreen/CoilTransferHistoryScreen';
import { ProfileMappingScreen } from '../../screens/ProfileMappingScreen/ProfileMappingScreen';
import { ProfileMappingHardwareAndTokoShopInfoScreen } from '../../screens/ProfileMappingHardwareAndTokoShopInfoScreen/ProfileMappingHardwareAndTokoShopInfoScreen';
import { ProfileMappingProductGroupScreen } from '../../screens/ProfileMappingProductGroupScreen/ProfileMappingProductGroupScreen';
import { ProfileMappingProductListScreen } from '../../screens/ProfileMappingProductListScreen/ProfileMappingProductListScreen';
import { ProfileMappingProductManageScreen } from '../../screens/ProfileMappingProductManageScreen/ProfileMappingProductManageScreen';
import { ProductDetailScreen } from '../../screens/ProductDetailScreen/ProductDetailScreen';
import { ProductDetailManageScreen } from '../../screens/ProductDetailManageScreen/ProductDetailManageScreen';
import { CustomersScreen } from '../../screens/CustomersScreen/CustomersScreen';
import { CustomersManageScreen } from '../../screens/CustomersManageScreen/CustomersManageScreen';
// import { RewardIndexesScreen } from '../../screens/RewardIndexesScreen/RewardIndexesScreen';
import { RedemptionScreen } from '../../screens/RedemptionScreen/RedemptionScreen';
import { RedemptionListOfRewardsScreen } from '../../screens/RedemptionListOfRewardsScreen/RedemptionListOfRewardsScreen';
import { RewardRedemptionHistoryScreen } from '../../screens/RewardRedemptionHistoryScreen/RewardRedemptionHistoryScreen';
import { PointsDetailScreen } from '../../screens/PointsDetailScreen/PointsDetailScreen';
import { PointsDetailManagementScreen } from '../../screens/PointsDetailManagementScreen/PointsDetailManagementScreen';
import { ReportScreen } from '../../screens/ReportScreen/ReportScreen';
// import { SettingsScreen } from '../../screens/SettingsScreen/SettingsScreen';
import { AdInformationScreen } from '../../screens/AdInformationScreen/AdInformationScreen';
// import { AdInformationChangePasswordScreen } from '../../screens/AdInformationChangePasswordScreen/AdInformationChangePasswordScreen';
// import { SalePersonScreen } from '../../screens/SalePersonScreen/SalePersonScreen';
// import { SalePersonManageScreen } from '../../screens/SalePersonManageScreen/SalePersonManageScreen';
import { TermAndConditionScreen } from '../../screens/TermAndConditionScreen/TermAndConditionScreen';
import { ContactUsScreen } from '../../screens/ContactUsScreen/ContactUsScreen';
import { HelpScreen } from '../../screens/HelpScreen/HelpScreen';
import { WarrantyScreen } from '../../screens/WarrantyScreen/WarrantyScreen';
import { WarrantyManageScreen } from '../../screens/WarrantyManageScreen/WarrantyManageScreen';
import { DocumentaryScreen } from '../../screens/DocumentaryScreen/DocumentaryScreen';
import { DocumentaryManageScreen } from '../../screens/DocumentaryManageScreen/DocumentaryManageScreen';
import { OrderInquiryScreen } from '../../screens/OrderInquiryScreen/OrderInquiryScreen';
import { OrderInquiryManageScreen } from '../../screens/OrderInquiryManageScreen/OrderInquiryManageScreen';
import { LoadingContainerConnected } from '../../containers/LoadingContainer';
import { addGA } from '../../helpers/functions/ga';
import { setCountryDataList } from '../../helpers/functions/data';
import { MainLayout } from '../../layouts/MainLayout/MainLayout';
// import { PublicNewsAndPromotionScreen } from '../../screens/PublicNewsAndPromotionScreen/PublicNewsAndPromotionScreen';
import { RewardStockScreen } from '../../screens/RewardStockScreen';
// import { UploadTransactionIndexesScreen } from '../../screens/UploadTransactionIndexesScreen';
import { UploadTransactionScreen } from '../../screens/UploadTransactionScreen';
import { UploadTransactionHistoryScreen } from '../../screens/UploadTransactionHistoryScreen';
import { UploadTransactionHistoryDetailScreen } from '../../screens/UploadTransactionHistoryDetailScreen';
import { isRolePermission, isCountryPermission } from '../../helpers/functions/permission';
import { PERMISSION, PERMISSION_COUNTRY_TYPE } from '../../helpers/constants/permission';
import { ProfileMappingHistoryScreen } from '../../screens/ProfileMappingHistoryScreen';
import { ENUM } from '../../helpers/constants/enum';
import { ProfileTransferScreen } from '../../screens/ProfileTransferScreen';
import { ProfileTransferHistoryScreen } from '../../screens/ProfileTransferHistoryScreen';
import { ProfileTransactionScreenConnected } from '../../screens/ProfileTransactionScreen/ProfileTransactionScreen';
import { ProfileTransactionManageScreenConnected } from '../../screens/ProfileTransactionManageScreen/ProfileTransactionManageScreen';
import { PublicNewsAndPromotionScreen } from '../../screens/PublicNewsAndPromotionScreen/PublicNewsAndPromotionScreen';
import { PublicWarrantyStatusScreen } from '../../screens/PublicWarrantyStatusScreen/PublicWarrantyStatusScreen';
import { SettingSalesPersonScreen } from '../../screens/SettingSalesPersonScreen';
import { SettingSalesPersonManageScreen } from '../../screens/SettingSalesPersonManageScreen';
import { DocumentaryViewListScreen } from '../../screens/DocumentaryViewListScreen';
import { WarrantyTemplateScreen } from '../../screens/WarrantyTemplateScreen';
import { PublicYearWarrantyScreen } from '../../screens/PublicYearWarrantyScreen';
import { ProjectDocumentCertificateManageScreen } from '../../screens/ProjectDocumentCertificateManageScreen';
import { ProjectDocumentReferenceManageScreen } from '../../screens/ProjectDocumentReferenceManageScreen';
import { CoilTransferBarcodeScannerScreen } from '../../screens/CoilTransferBarcodeScannerScreen';
import { CoilApprovalScreen } from '../../screens/CoilApprovalScreen';
import { ProjectDocumentDocumentaryManageScreen } from '../../screens/ProjectDocumentDocumentaryManageScreen';
import { AuthenticationScreen } from '../../screens/AuthenticationScreen';
import { ProjectDocumentScreen } from '../../screens/ProjectDocumentScreen';
import { ProjectDocumentManageScreen } from '../../screens/ProjectDocumentManageScreen';
import { ProjectDocumentInformationScreen } from '../../screens/ProjectDocumentInformationScreen';
import { NotificationManagementScreen } from '../../screens/NotificationManagementScreen';
import { handleValidateAuth } from '../../helpers/functions/auth';
import { PublicWarrantyAndDocumentaryScreen } from '../../screens/PublicWarrantyAndDocumentaryScreen';
import { clearLocalStorage } from '../../helpers/functions/localStorage';

export class RouteContainers extends React.Component {

  constructor(props) {
    super(props);
    setRouteHistory(props);
    clearLocalStorage();

    const userAuth = getUserAuth();
    setUserAuthRedux(userAuth);
    if (userAuth) {
      setCountryDataList(userAuth.addressType);
      // for check log
      getLastOTPSuccessDay(userAuth.email);
    }

    const isOtpAuthSuccess = getAuthSuccess();
    this.state = {
      userAuth: isOtpAuthSuccess ? userAuth : undefined,
      toastAnimation: cssTransition({
        enter: 'slideDownFadeIn',
        exit: 'slideDownFadeOut',
        duration: 300
      })
    };
    console.log('userAuth', userAuth)
  }


  render() {
    const {
      location,
    } = this.props;
    const {
      userAuth,
      toastAnimation,
    } = this.state;

    const isCanUseApp = handleCheckMaintenance();
    if (userAuth) {
      handleValidateAuth();
    }

    return (
      <React.Fragment>
        {isCanUseApp ?
          <Switch>
            <Route exact path={ROUTE_PATH.PUBLIC.NEWS_AND_PROMOTION.LINK_PARAMS} component={(props) => { addGA(); return <PublicNewsAndPromotionScreen {...props} /> }} />
            <Route exact path={ROUTE_PATH.PUBLIC.WARRANTY_STATUS.LINK_PARAMS} component={(props) => { addGA(); return <PublicWarrantyStatusScreen {...props} /> }} />
            <Route exact path={ROUTE_PATH.PUBLIC.YEAR_WARRANTY.LINK} component={(props) => { addGA(); return <PublicYearWarrantyScreen {...props} /> }} />
            <Route exact path={ROUTE_PATH.AUTHENTICATION.LINK} component={(props) => { addGA(); return <AuthenticationScreen {...props} /> }} />
            <Route exact path={ROUTE_PATH.PUBLIC.WARRANTY_AND_DOCUMENTARY.LINK} component={(props) => { addGA(); return <PublicWarrantyAndDocumentaryScreen {...props} /> }} />
            {!!userAuth ?
              <MainLayout>
                <Route exact path={ROUTE_PATH.HOME.LINK} component={(props) => { addGA(); return <HomeScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.YEAR_WARRANTY.LINK} component={(props) => { addGA(); return <YearWarrantyScreen {...props} /> }} />
                {/* <Route exact path={ROUTE_PATH.SALES_TRANSACTION.LINK} component={(props) => { addGA(); return <SalesTransactionIndexesScreen {...props} /> }} /> */}
                <Route exact path={ROUTE_PATH.SALES_ORDERS.LINK} component={(props) => { addGA(); return <SalesOrdersScreen {...props} /> }} />
                {isRolePermission(PERMISSION.SIDE_MENU.SALES_TRANSACTION.SALE_HISTORY) &&
                  <React.Fragment>
                    <Route exact path={ROUTE_PATH.SALES_HISTORY.LINK} component={(props) => { addGA(); return <SalesHistoryScreen {...props} /> }} />
                    <Route exact path={`${ROUTE_PATH.SALES_HISTORY.LINK}/:id`} component={(props) => { addGA(); return <SalesHistoryScreen {...props} /> }} />
                  </React.Fragment>
                }
                <Route exact path={ROUTE_PATH.WARRANTY.LINK} component={(props) => { addGA(); return <WarrantyScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.WARRANTY.LINK}/:id`} component={(props) => { addGA(); return <WarrantyManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.WARRANTY_TEMPLATE.LINK} component={(props) => { addGA(); return <WarrantyTemplateScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.SPECIAL_WARRANTY.LINK} component={(props) => { addGA(); return <SpecialWarrantyScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.DOCUMENTARY.LINK} component={(props) => { addGA(); return <DocumentaryScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.DOCUMENTARY_VIEW_LIST.LINK}/:id`} component={(props) => { addGA(); return <DocumentaryViewListScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.DOCUMENTARY_MANAGE.LINK} component={(props) => { addGA(); return <DocumentaryManageScreen {...props} /> }} />
                {/* <Route exact path={ROUTE_PATH.INVENTORY_INDEXES.LINK} component={(props) => { addGA(); return <InventoryIndexesScreen {...props} /> }} /> */}
                <Route exact path={ROUTE_PATH.INVENTORY.LINK} component={(props) => { addGA(); return <InventoryScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.COIL_TRANSFER.LINK} component={(props) => { addGA(); return <CoilTransferScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.COIL_APPROVAL.LINK} component={(props) => { addGA(); return <CoilApprovalScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_TRANSFER.LINK} component={(props) => { addGA(); return <ProfileTransferScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.AUTHORISED_DEALER_AND_ROLL_FORMER_MANAGE.LINK} component={(props) => { addGA(); return <AuthorisedDealerAndRollFormerManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.COIL_TRANSACTION.LINK} component={(props) => { addGA(); return <CoilTransactionScreenConnected {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_TRANSACTION.LINK} component={(props) => { addGA(); return <ProfileTransactionScreenConnected {...props} /> }} />
                <Route exact path={ROUTE_PATH.COIL_TRANSACTION_MANAGE.LINK} component={(props) => { addGA(); return <CoilTransactionManageScreenConnected {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_TRANSACTION_MANAGE.LINK} component={(props) => { addGA(); return <ProfileTransactionManageScreenConnected {...props} /> }} />
                <Route exact path={ROUTE_PATH.COIL_TRANSFER_HISTORY.LINK} component={(props) => { addGA(); return <CoilTransferHistoryScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_TRANSFER_HISTORY.LINK} component={(props) => { addGA(); return <ProfileTransferHistoryScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_MAPPING.LINK} component={(props) => { addGA(); return <ProfileMappingScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK} component={(props) => { addGA(); return <ProfileMappingHardwareAndTokoShopInfoScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK} component={(props) => { addGA(); return <ProfileMappingProductGroupScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_MAPPING_PRODUCT_LIST.LINK} component={(props) => { addGA(); return <ProfileMappingProductListScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.PROFILE_MAPPING_PRODUCT_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <ProfileMappingProductManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROFILE_MAPPING_HISTORY.LINK} component={(props) => { addGA(); return <ProfileMappingHistoryScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PRODUCT_DETAIL.LINK} component={(props) => { addGA(); return <ProductDetailScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.PRODUCT_DETAIL.LINK}/:id`} component={(props) => { addGA(); return <ProductDetailManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.CUSTOMERS.LINK} component={(props) => { addGA(); return <CustomersScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.CUSTOMERS_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <CustomersManageScreen {...props} /> }} />
                {/* <Route exact path={ROUTE_PATH.REWARDS.LINK} component={(props) => { addGA(); return <RewardIndexesScreen {...props} /> }} /> */}
                <Route exact path={ROUTE_PATH.REDEMPTION.LINK} component={(props) => { addGA(); return <RedemptionScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.REDEMPTION_LIST_OF_REWARDS.LINK} component={(props) => { addGA(); return <RedemptionListOfRewardsScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.REWARD_STOCK.LINK} component={(props) => { addGA(); return <RewardStockScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.REWARD_REDEMPTION_HISTORY.LINK} component={(props) => { addGA(); return <RewardRedemptionHistoryScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.REWARD_REDEMPTION_HISTORY.LINK}/:id`} component={(props) => { addGA(); return <RewardRedemptionHistoryScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.POINTS_DETAIL.LINK} component={(props) => { addGA(); return <PointsDetailScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.POINTS_DETAIL_MANAGEMENT.LINK}/:id`} component={(props) => { addGA(); return <PointsDetailManagementScreen {...props} /> }} />
                {isRolePermission(PERMISSION.SIDE_MENU.REPORT.ALL) &&
                  <React.Fragment>
                    <Route exact path={ROUTE_PATH.REPORT.LINK} component={(props) => { addGA(); return <ReportScreen {...props} /> }} />
                  </React.Fragment>
                }
                <Route exact path={ROUTE_PATH.CHANGE_PASSWORD.LINK} component={(props) => { addGA(); return <AdInformationScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.NOTIFICATION_MANAGEMENT.LINK} component={(props) => { addGA(); return <NotificationManagementScreen {...props} /> }} />
                {isRolePermission(PERMISSION.SIDE_MENU.SETTINGS.ALL) &&
                  <React.Fragment>
                    {/* <Route exact path={ROUTE_PATH.SETTINGS.LINK} component={(props) => { addGA(); return <SettingsScreen {...props} /> }} /> */}
                    <Route exact path={ROUTE_PATH.AD_INFORMATION.LINK} component={(props) => { addGA(); return <AdInformationScreen {...props} /> }} />
                    {/* <Route exact path={ROUTE_PATH.AD_INFORMATION_CHANGE_PASSWORD.LINK} component={(props) => { addGA(); return <AdInformationChangePasswordScreen {...props} /> }} /> */}
                    {/* <Route exact path={ROUTE_PATH.SALE_PERSON_INFORMATION.LINK} component={(props) => { addGA(); return <SalePersonScreen {...props} /> }} /> */}
                    {/* <Route exact path={`${ROUTE_PATH.SALE_PERSON_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <SalePersonManageScreen {...props} /> }} /> */}
                    <Route exact path={ROUTE_PATH.TERMS_AND_CONDITION.LINK} component={(props) => { addGA(); return <TermAndConditionScreen {...props} /> }} />
                    <Route exact path={ROUTE_PATH.CONTACT_US.LINK} component={(props) => { addGA(); return <ContactUsScreen {...props} /> }} />
                    <Route exact path={ROUTE_PATH.HELP.LINK} component={(props) => { addGA(); return <HelpScreen {...props} /> }} />
                    <Route exact path={ROUTE_PATH.SETTING_SALES_PERSON.LINK} component={(props) => { addGA(); return <SettingSalesPersonScreen {...props} /> }} />
                    <Route exact path={`${ROUTE_PATH.SETTING_SALES_PERSON_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <SettingSalesPersonManageScreen {...props} /> }} />
                  </React.Fragment>
                }

                <Route exact path={ROUTE_PATH.PROJECT_DOCUMENT.LINK} component={(props) => { addGA(); return <ProjectDocumentScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.PROJECT_DOCUMENT_INFORMATION.LINK}/:id`} component={(props) => { addGA(); return <ProjectDocumentInformationScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <ProjectDocumentManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.CERTIFICATE_MANAGE.LINK} component={(props) => { addGA(); return <ProjectDocumentCertificateManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.PROJECT_DOCUMENT_DOCUMENTARY_MANAGE_SCREEN.LINK} component={(props) => { addGA(); return <ProjectDocumentDocumentaryManageScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.PROJECT_DOCUMENT_REFERENCE_MANAGE.LINK}/:id`} component={(props) => { addGA(); return <ProjectDocumentReferenceManageScreen {...props} /> }} />
                <Route exact path={ROUTE_PATH.ORDER_INQUIRY.LINK} component={(props) => { addGA(); return <OrderInquiryScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.ORDER_INQUIRY.LINK}/:id`} component={(props) => { addGA(); return <OrderInquiryManageScreen {...props} /> }} />
                <Route exact path={`${ROUTE_PATH.COIL_TRANSFER_BARCODE_SCANNER.LINK}`} component={(props) => { addGA(); return <CoilTransferBarcodeScannerScreen {...props} /> }} />
                {isCountryPermission(PERMISSION_COUNTRY_TYPE.SIDE_MENU.UPLOAD_TRANSACTION) &&
                  <React.Fragment>
                    {/* <Route exact path={`${ROUTE_PATH.UPLOAD_TRANSACTION_INDEXES.LINK}`} component={(props) => { addGA(); return <UploadTransactionIndexesScreen {...props} /> }} /> */}
                    <Route exact path={`${ROUTE_PATH.UPLOAD_TRANSACTION.LINK}`} component={(props) => { addGA(); return <UploadTransactionScreen {...props} /> }} />
                    <Route exact path={`${ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY.LINK}`} component={(props) => { addGA(); return <UploadTransactionHistoryScreen {...props} /> }} />
                    <Route exact path={`${ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY_DETAIL.LINK}/:id`} component={(props) => { addGA(); return <UploadTransactionHistoryDetailScreen {...props} /> }} />
                  </React.Fragment>
                }
              </MainLayout>
              :
              <React.Fragment>
                <Route exact path={ROUTE_PATH.LOGIN.LINK} component={(props) => { addGA(); return <LoginScreen {...props} /> }} />
                <Redirect to={ROUTE_PATH.LOGIN.LINK} />
              </React.Fragment>
            }
          </Switch>
          :
          <Switch>
            <Route exact path={ROUTE_PATH.AUTHENTICATION.LINK} component={(props) => { addGA(); return <AuthenticationScreen {...props} /> }} />
            <Route exact path={ROUTE_PATH.LOGIN.LINK} component={(props) => { addGA(); return <LoginScreen {...props} /> }} />
            <Redirect to={ROUTE_PATH.LOGIN.LINK} />
          </Switch>
        }
        <ToastContainer closeOnClick hideProgressBar
          className='react-toastify'
          position='top-center'
          transition={toastAnimation}
        />
        <LoadingContainerConnected />
        <ModalConfirmDeleteContainerConnected />
        <ModalConfirmContainerConnected />
      </React.Fragment>
    )
  }
}

// const mapStateToProps = (state) => {
//   console.log('xxxxxx')
//   return {
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export const RouteContainersConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(RouteContainers)