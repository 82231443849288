import React from 'react';
import { authController } from '../../apiService/apiController/authService';
import { otpController } from '../../apiService/apiController/otpService';
import { userController } from '../../apiService/apiController/userService';
import { TEXT_VERSION } from '../../config/config';
import { DATA } from '../../helpers/constants/data';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { firebaseUnsubscribeOTPAuth, handleWatchOTPAuth } from '../../helpers/firebases/firebaseOTPAuth';
import { getLastOTPSuccessDay, getUserAuth, setLastOTPSuccessDay } from '../../helpers/functions/auth';
import { getCountryPhone, getCountryType, setCountryDataList } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../helpers/functions/localStorage';
import { toast } from '../../helpers/functions/main';
import { isValidNumber, isValidPhoneNumber, isValidResponse } from '../../helpers/functions/validation';
import { ModelOTP } from '../../models/ModelOTP';
import { ModelUserAuth } from '../../models/ModelUserAuth';
import {
  Button, Container, Field,
  Grid, Image, Section, ShapeContainer, Text
} from './../../components';
import {
  CONTENTS,
  ICONS, VARIABLES
} from './../../themes';
import { storeGetState } from '../../stores';

export class LoginScreen extends React.Component {

  constructor(props) {
    super(props);
    const remember = getLocalStorage(LOCAL_STORAGE.REMEMBER_ME_RBPTRUEBLUE) || {};
    const statusLoginList = ['Login', 'SendOtp', 'SetupQuestion'];
    const statusForgetList = ['SelectMethod', 'PhoneNumber', 'VerifyOtp', 'Question', 'ResetPassword', 'ResetSuccess'];

    const maintenanceData = storeGetState().mainRedux.maintenance;
    this.state = {
      isRememberMe: remember.isRememberMe ? remember.isRememberMe : false,
      email: remember.email || '',
      password: '',
      resetPassword: '',
      confirmResetPassword: '',
      // emailForgetPassword: '',
      phoneForgetPassword: '',
      countryPhoneForgetPassword: getCountryPhone(),
      status: statusLoginList[0],
      // status: statusForgetList[3],
      // isOpenForgetPassword: true,
      statusLoginList,
      statusForgetList,
      otpData: {},
      otpInputList: ['', '', '', '', ''],
      questionInput1: '',
      questionInput2: '',
      questionInput3: '',
      sendPhoneLoginOtp: '',
      maximumOTPSuccesDay: 30,
      maintenanceData: maintenanceData,
    }
  }

  componentDidMount() {
    if (getLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN)) {
      toast.info(getLanguage('login.message_30'));
      removeLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN);
    }
  }

  login = {
    getTitleText: () => {
      const {
        isOpenForgetPassword,
        status,
        statusLoginList,
        statusForgetList,
      } = this.state;
      let text1 = '';
      let text2 = '';
      if (isOpenForgetPassword) {
        switch (status) {
          case statusForgetList[0]:
            {/* RL-707 > Verify Methods */ }
            text1 = getLanguage('login.message_13');
            text2 = getLanguage('login.message_14');
            break;
          case statusForgetList[1]:
            {/* RL-707 > Verify with Phone Number */ }
            text1 = getLanguage('login.message_15');
            text2 = getLanguage('login.message_16');
            break;
          case statusForgetList[2]:
            {/* RL-707 > Verify with OTP */ }
            text1 = getLanguage('login.message_15');
            text2 = getLanguage('login.message_18');
            break;
          case statusForgetList[3]:
            {/* RL-707 > Verify with Questions */ }
            text1 = getLanguage('login.message_15');
            text2 = getLanguage('login.message_17');
            break;
          case statusForgetList[4]:
            {/* RL-707 > Reset Password */ }
            text1 = getLanguage('login.message_27');
            text2 = getLanguage('login.message_28');
            break;
          case statusForgetList[5]:
            {/* RL-707 > Reset Password Success */ }
            text1 = getLanguage('login.message_25');
            text2 = getLanguage('login.message_10');
            break;
        }
      }
      else {
        switch (status) {
          case statusLoginList[0]:
            //Login
            text1 = getLanguage('login.message_1');
            text2 = getLanguage('login.message_2');
            break;
          case statusLoginList[1]:
            {/* RL-707 > Check Authentication */ }
            text1 = getLanguage('login.message_5');
            text2 = getLanguage('login.message_6');
            break;
          case statusLoginList[2]:
            {/* RL-707 > Set up Questions */ }
            text1 = getLanguage('login.message_29');
            text2 = getLanguage('login.message_17');
            break;
        }
      }
      return <React.Fragment>
        <Text regular18 color={VARIABLES.COLORS.BLACK}>
          {text1}
          &nbsp;
        </Text>
        <Text bold18 color={VARIABLES.COLORS.PRIMARY_2}>
          {text2}
        </Text>
      </React.Fragment >
    },
    goToHomePage: () => {
      setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS, true);
      window.location.href = window.location.origin;
    },
    onClickLogin: async () => {
      const {
        isRememberMe,
        email,
        password,
        statusLoginList,
        maximumOTPSuccesDay,
        maintenanceData,
      } = this.state
      // redirect to azure prod
      window.location.href = 'https://trueblue.nsbluescope.com/';

      // if (
      //   maintenanceData.isMaintenance &&
      //   maintenanceData.userWhiteList.indexOf(email) === -1
      // ) {
      //   const messageError = `${getLanguage('login.maintenance')} ${maintenanceData.textTime} ${getLanguage('login.maintenance_2')}`
      //   toast.error(messageError);
      //   return;
      // }

      // const params = {
      //   email,
      //   password
      // };
      // const authService = authController();
      // const res = await authService.loginByEmail(params);
      // console.log('res', res);
      // if (isValidResponse(res)) {
      //   if (isRememberMe) {
      //     setLocalStorage(LOCAL_STORAGE.REMEMBER_ME_RBPTRUEBLUE, {
      //       isRememberMe,
      //       email
      //     })
      //   }
      //   else {
      //     removeLocalStorage(LOCAL_STORAGE.REMEMBER_ME_RBPTRUEBLUE)
      //   }
      //   let userAuth = res.data;
      //   setCountryDataList(getCountryType(userAuth.country__c));
      //   userAuth = new ModelUserAuth(userAuth);
      //   setLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE, userAuth);

      //   const sendPhone = `${userAuth.mobileNoCountry.value}${userAuth.mobileNo}`
      //   console.log('sendPhoneLoginOtp', sendPhone)
      //   this.setState({
      //     sendPhoneLoginOtp: sendPhone,
      //   })
      //   if (getLastOTPSuccessDay(email) <= maximumOTPSuccesDay) {
      //     // for login without otp
      //     this.login.goToHomePage();
      //   }
      //   else {
      //     // for login with otp
      //     this.login.sendLoginOtp(sendPhone);
      //   }
      // }
      // else {
      //   toast.error(getLanguage('message.incorrectEmailOrPassword', ''));
      // }
    },
    onClickForgetPassword: () => {
      const {
        statusForgetList,
      } = this.state;
      this.setState({
        isOpenForgetPassword: true,
        // emailForgetPassword: '',
        phoneForgetPassword: '',
        countryPhoneForgetPassword: getCountryPhone(),
        status: statusForgetList[0]
      })
    },
    onClickBackToLogin: () => {
      const {
        location,
      } = this.props;
      const {
        statusLoginList,
      } = this.state;
      if (location.search !== '') {
        window.location.href = window.location.origin;
      }
      else {
        this.setState({
          isOpenForgetPassword: false,
          status: statusLoginList[0]
        })
      }
      firebaseUnsubscribeOTPAuth();
    },
    // onClickSendForgetPassword: async () => {
    //   const {
    //     emailForgetPassword,
    //     phoneForgetPassword,
    //     countryPhoneForgetPassword,
    //   } = this.state;
    //   const sendPhone = `${countryPhoneForgetPassword.value}${phoneForgetPassword}`;
    //   console.log('sendPhone', sendPhone);
    //   const params = {
    //     email: emailForgetPassword,
    //     phone_no: sendPhone
    //   };
    //   const adService = adController();
    //   const res = await adService.forgetPassword(params);
    //   console.log('res', res);
    //   if (isValidResponse(res)) {
    //     toast.success(getLanguage('message.sendPasswordToPhone', ''));
    //     this.login.onClickBackToLogin();
    //   }
    //   else {
    //     toast.error(res.message);
    //   }
    // },
    sendLoginOtp: async (number) => {
      const {
        statusLoginList,
        email,
      } = this.state;
      console.log('send phone', number)
      const params = {
        to: number,
        message_type: 'login',
        url: `${window.location.origin}${ROUTE_PATH.AUTHENTICATION.LINK}`,
      }
      const otpService = otpController();
      const res = await otpService.otpSend(params);
      if (isValidResponse(res)) {
        const otpData = new ModelOTP(res);
        handleWatchOTPAuth(otpData, () => {
          firebaseUnsubscribeOTPAuth();
          const userAuth = getUserAuth();
          if (userAuth.securityAnswer1__c && userAuth.securityAnswer2__c && userAuth.securityAnswer3__c) {
            setLastOTPSuccessDay({
              username: email,
              otpSuccessDate: new Date(),
            });
            this.login.goToHomePage();
          }
          else {
            this.setState({
              status: statusLoginList[2],
              questionInput1: '',
              questionInput2: '',
              questionInput3: '',
            })
          }
        });

        this.setState({
          status: statusLoginList[1],
          otpData
        })
      }
    },
    onClickResendLoginOtp: () => {
      const {
        sendPhoneLoginOtp,
      } = this.state;
      this.login.sendLoginOtp(sendPhoneLoginOtp);
    },
    onClickVerifyPhoneNumber: () => {
      const {
        statusForgetList,
      } = this.state;
      this.setState({
        status: statusForgetList[1]
      })
    },
    onClickSubmitForgetPasswordSendOtp: () => {
      const {
        statusForgetList,
        phoneForgetPassword,
        countryPhoneForgetPassword,
      } = this.state;
      let isPass = true;

      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (countryPhoneForgetPassword.value === e.value) {
          if (e.lengthList.indexOf(phoneForgetPassword.length) === -1) {
            toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
            isPass = false;
          }
        }
      });

      if (isPass) {
        const sendPhone = `${countryPhoneForgetPassword.value}${phoneForgetPassword}`;
        console.log('sendPhone', sendPhone);
        this.login.sendForgetPasswordOtp(sendPhone, () => {
          toast.success(getLanguage('message.otpSentSuccess'));
          this.setState({
            status: statusForgetList[2],
            otpInputList: ['', '', '', '', '']
          })
        });
      }
    },
    sendForgetPasswordOtp: async (number, callback = () => { }) => {
      const paramsVerifyUser = {
        phone: number
      }
      const userService = userController();
      const resVerifyUser = await userService.verifyUser(paramsVerifyUser);
      if (isValidResponse(resVerifyUser)) {
        const verifyData = resVerifyUser.email;
        const params = {
          to: number,
          message_type: 'otp'
        }
        const otpService = otpController();
        const res = await otpService.otpSend(params);
        if (isValidResponse(res)) {
          const otpData = new ModelOTP(res);
          this.setState({
            otpData,
            verifyData
          }, () => {
            callback();
          })
        }
      }
    },
    onClickForgetPasswordResendOtp: () => {
      const {
        phoneForgetPassword,
        countryPhoneForgetPassword,
      } = this.state;
      const sendPhone = `${countryPhoneForgetPassword.value}${phoneForgetPassword}`;
      this.login.sendForgetPasswordOtp(sendPhone, () => {
        toast.success(getLanguage('message.otpResendSuccess'));
      });
    },
    onClickSubmitVerifyPhoneNumber: async () => {
      const {
        otpInputList,
        otpData,
        statusForgetList,
      } = this.state;
      const otpInput = otpInputList.join('');
      if (otpInput.length === otpInputList.length) {
        const params = {
          token: otpInput,
          secret: otpData.secret,
        }
        const otpService = otpController();
        const res = await otpService.otpVerify(params);
        if (isValidResponse(res)) {
          this.setState({
            resetPassword: '',
            isShowResetPassword: false,
            confirmResetPassword: '',
            isShowConfirmResetPassword: false,
            status: statusForgetList[4],
            isErrorOTP: false,
          });
        }
        else {
          toast.error(res.message);
          this.setState({
            isErrorOTP: true,
          })
        }
      }
      else {
        toast.error(getLanguage('message.incorrectOTP'));
        this.setState({
          isErrorOTP: true,
        })
      }
    },
    onClickVerifyWithQuestion: () => {
      const {
        statusForgetList,
      } = this.state;
      this.setState({
        status: statusForgetList[3],
        questionInput1: '',
        questionInput2: '',
        questionInput3: '',
      })
    },
    isValidateVerifyQuestionPass: () => {
      const {
        questionInput1,
        questionInput2,
        questionInput3,
      } = this.state;
      let isPass = true;

      if (
        questionInput1.length === 0 ||
        questionInput2.length === 0 ||
        questionInput3.length === 0
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField'));
        isPass = false;
      }

      return isPass;
    },
    onClickSubmitSetupVerifyQuestion: async () => {
      const {
        questionInput1,
        questionInput2,
        questionInput3,
        email
      } = this.state;
      if (this.login.isValidateVerifyQuestionPass()) {
        const params = {
          security_answer_1: questionInput1,
          security_answer_2: questionInput2,
          security_answer_3: questionInput3,
          email
        }
        const userService = userController();
        const res = await userService.securtyAnswer(params);
        if (isValidResponse(res)) {
          const userAuth = getUserAuth();
          userAuth.securityAnswer1__c = questionInput1;
          userAuth.securityAnswer2__c = questionInput2;
          userAuth.securityAnswer3__c = questionInput3;
          setLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE, userAuth);
          setLastOTPSuccessDay({
            username: email,
            otpSuccessDate: new Date(),
          });
          this.login.goToHomePage();
        }
      }
    },
    onClickSubmitVerifyQuestion: async () => {
      const {
        statusForgetList,
        questionInput1,
        questionInput2,
        questionInput3,
      } = this.state;
      if (this.login.isValidateVerifyQuestionPass()) {
        const paramsVerifyUser = {
          security_answer_1: questionInput1,
          security_answer_2: questionInput2,
          security_answer_3: questionInput3
        }
        const configService = {
          isShowError: false,
        }
        const userService = userController(configService);
        const resVerifyUser = await userService.verifyUser(paramsVerifyUser);
        if (isValidResponse(resVerifyUser)) {
          const verifyData = resVerifyUser.email;
          this.setState({
            status: statusForgetList[4],
            verifyData
          })
        }
        else {
          toast.error(getLanguage('message.wrongAnswer'));
        }
      }
    },
    goToForgetPasswordMethod: () => {
      const {
        statusForgetList,
      } = this.state;
      this.setState({
        status: statusForgetList[0]
      })
    },
    isValidateResetPasswordPass: () => {
      const {
        resetPassword,
        confirmResetPassword,
      } = this.state;
      let isPass = true;
      if (resetPassword.length === 0 ||
        confirmResetPassword === 0) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      else if (resetPassword.length < 8 || resetPassword.length > 24) {
        toast.error(getLanguage('message.passwordIncorrectLength', ''));
        isPass = false;
      }
      else if (resetPassword !== confirmResetPassword) {
        toast.error(getLanguage('message.newPasswordAndConfirmPasswordNotMatch', ''));
        isPass = false;
      }
      return isPass
    },
    onClickSubmitResetPassword: async () => {
      const {
        statusForgetList,
        resetPassword,
        verifyData,
      } = this.state;
      if (this.login.isValidateResetPasswordPass()) {
        const params = {
          email: verifyData,
          password: resetPassword,
        }
        const userService = userController();
        const res = await userService.resetPassword(params);
        if (isValidResponse(res)) {
          this.setState({
            status: statusForgetList[5]
          })
        }
      }
    },
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      let value = ev.target.value;
      const checked = ev.target.checked;
      switch (name) {
        case 'email':
        case 'password':
        case 'resetPassword':
        case 'confirmResetPassword':
        case 'questionInput1':
        case 'questionInput2':
        case 'questionInput3':
          // case 'emailForgetPassword':
          value = value.trim();
          this.setState({
            [name]: value
          });
          break;
        case 'rememberMe':
          this.setState({
            isRememberMe: checked,
          });
          break;
        case 'phoneForgetPassword':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
          this.setState({
            [name]: value
          });
          break;
        default:
          this.setState({
            [name]: value
          });
      }
    },
    onChangeOtpInput: (ev, index) => {
      const {
        otpInputList,
      } = this.state;
      const value = ev.target.value;
      if (value.length && !isValidNumber(value)) {
        return;
      }
      if (value.length > 0 && index + 1 < otpInputList.length) {
        document.getElementsByName('otpInput' + (index + 1))[0].focus();
      }
      otpInputList[index] = value;
      this.setState({
        otpInputList
      })
    },
    onChangePhoneCountry: (selected) => {
      this.setState({
        countryPhoneForgetPassword: selected,
      })
    },
    toggleIsShowResetPassword: () => {
      const {
        isShowResetPassword,
      } = this.state;
      this.setState({
        isShowResetPassword: !isShowResetPassword
      })
    },
    toggleIsShowConfirmResetPassword: () => {
      const {
        isShowConfirmResetPassword,
      } = this.state;
      this.setState({
        isShowConfirmResetPassword: !isShowConfirmResetPassword
      })
    },
  }

  render() {
    const {
      isRememberMe,
      email,
      password,
      // emailForgetPassword,
      phoneForgetPassword,
      countryPhoneForgetPassword,
      status,
      statusLoginList,
      statusForgetList,
      otpData,
      otpInputList,
      isErrorOTP,
      resetPassword,
      confirmResetPassword,
      isShowResetPassword,
      isShowConfirmResetPassword,
      questionInput1,
      questionInput2,
      questionInput3,
      maintenanceData,
    } = this.state
    return (
      <Container
        className='is-login'
        ui='fullViewBgImageCover'
        bgImage={CONTENTS['bg-login.jpg']}
      >
        <Container
          ui='fullViewCenter'
          bgColor={VARIABLES.COLORS.OVERLAY_BLUE_1}
        >
          <ShapeContainer ui='login' className='is-login'>
            <Section
              className='is-login-logo'
              justify='center'
              spacingBottom='55'
            >
              <Image ui='logoLogin' className='is-login-logo' />
            </Section>
            <React.Fragment>
              {
                maintenanceData.isMaintenance ?
                  <React.Fragment>
                    <Section justify='center' align='center'>
                      <Section justify='center' spacingBottom='16'>
                        <Text bold24 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('login.maintenance')}</Text>
                      </Section>
                      <Section justify='center' spacingBottom='16'>
                        <Text regular18 color={VARIABLES.COLORS.PRIMARY_2}>{maintenanceData.textTime}</Text>
                      </Section>
                      <Section justify='center' spacingBottom='16'>
                        <Text regular14 color={VARIABLES.COLORS.GRAY_19}>{getLanguage('login.maintenance_2')}</Text>
                      </Section>
                    </Section>
                  </React.Fragment>
                  :
                  <Section
                    className='is-login-title'
                    justify='center'
                    spacingBottom='45'
                  >
                    {this.login.getTitleText()}
                  </Section>
              }
              {/* RL-707 > Login */}
              {status === statusLoginList[0] &&
                <Section paddingHorizontal='45'>
                  {/* <Section spacingBottom='25'>
                    <Field name='email'
                      fluid
                      error={false} // This props control error validation
                      focus={false} // This props control onBlur state for change label and field border color
                      ui='lineLabelVertical'
                      label={getLanguage('field.email')}
                      placeholder={getLanguage('field.emailPlaceholder')}
                      onChange={this.input.onChangeInput}
                      value={email}
                    />
                  </Section>
                  <Section spacingBottom='20'>
                    <Field name='password'
                      fluid
                      error={false} // This props control error validation
                      focus={false} // This props control onBlur state for change label and field border color
                      inputTextType='password'
                      ui='lineLabelVertical'
                      label={getLanguage('field.password')}
                      placeholder={getLanguage('field.passwordPlaceholder')}
                      onChange={this.input.onChangeInput}
                      value={password}
                    />
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Field name='rememberMe'
                          checked={isRememberMe}
                          ui='checkbox'
                          checkboxLabel={getLanguage('field.rememberMe')}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      {!maintenanceData.isMaintenance &&
                        <Grid.Column>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.PRIMARY_2}
                            onClick={this.login.onClickForgetPassword}
                          >
                            {getLanguage('login.message_3')}
                          </Text>
                        </Grid.Column>
                      }
                    </Grid>
                  </Section> */}
                  <Section justify='center'>
                    <Button
                      blue
                      name={getLanguage('field.buttonLogin').toUpperCase()}
                      width={VARIABLES.BUTTON.WIDTHS.W_165}
                      // onClick={this.login.onClickLogin}
                      onClick={() => window.location.href = 'https://trueblue.nsbluescope.com/'}
                    />
                  </Section>
                </Section>
              }
              {/* RL-707 > Check Authentication */}
              {status === statusLoginList[1] &&
                <React.Fragment>
                  <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.BLUE_5} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='10'>
                    <Section justify='center' spacingBottom='20'>
                      <img src={ICONS['ic-phone-ref-code-blue.svg']} alt='Icon' />
                    </Section>
                    <Section justify='center'>
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_7')}</Text>
                      <br />
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_8')}</Text>
                      <br />
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_9')}</Text>
                      &nbsp;
                      <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>{otpData.referenceCode}</Text>
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>.</Text>
                    </Section>
                  </ShapeContainer>
                  <Section justify='center' paddingTop='45' spacingBottom='45'>
                    <Button
                      outlineBlue
                      name={getLanguage('field.buttonReSendAuthenticationLink').toUpperCase()}
                      onClick={this.login.onClickResendLoginOtp}
                    />
                  </Section>
                  <Section justify='center'>
                    <Section width='auto' onClick={this.login.onClickBackToLogin}>
                      <Grid align='center' gutter='10'>
                        <Grid.Column>
                          <Section paddingBottom='3'><img src={ICONS['ic-arrow-back-blue.svg']} alt='Icon' /></Section>
                        </Grid.Column>
                        <Grid.Column><Text medium14 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.buttonToLogin').toUpperCase()}</Text></Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                </React.Fragment>
              }
              {/* RL-707 > Setup Questions */}
              {status === statusLoginList[2] &&
                <React.Fragment>
                  <Section paddingHorizontal='45' spacingBottom='45'>
                    <Section spacingBottom='30'>
                      <Field name='questionInput1'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_21')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput1}
                      />
                    </Section>
                    <Section spacingBottom='30'>
                      <Field name='questionInput2'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_22')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput2}
                      />
                    </Section>
                    <Section>
                      <Field name='questionInput3'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_23')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput3}
                      />
                    </Section>
                  </Section>
                  <Grid justify='center' gutter='15'>
                    <Grid.Column>
                      <Button
                        outlineBlue
                        name={getLanguage('field.buttonBack').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.onClickBackToLogin}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        name={getLanguage('field.buttonSubmit').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.onClickSubmitSetupVerifyQuestion}
                      />
                    </Grid.Column>
                  </Grid>
                </React.Fragment>
              }
              {/* RL-707 > Verify Methods */}
              {status === statusForgetList[0] &&
                <React.Fragment>
                  <Grid gutter='30'>
                    <Grid.Column grid='6'>
                      <ShapeContainer onClick={this.login.onClickVerifyPhoneNumber} fluid justify='center' align='center' height='248' borderWidth='2' borderStyle='solid' borderColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='10'>
                        <Section justify='center' spacingBottom='20'>
                          <img src={ICONS['ic-verify-phone-blue.svg']} alt='Icon' />
                        </Section>
                        <Section justify='center'>
                          <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_15')}</Text>
                          &nbsp;
                          <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('login.message_16')}</Text>
                        </Section>
                      </ShapeContainer>
                    </Grid.Column>
                    <Grid.Column grid='6'>
                      <ShapeContainer onClick={this.login.onClickVerifyWithQuestion} fluid justify='center' align='center' height='248' borderWidth='2' borderStyle='solid' borderColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='10'>
                        <Section justify='center' spacingBottom='20'>
                          <img src={ICONS['ic-verify-question-blue.svg']} alt='Icon' />
                        </Section>
                        <Section justify='center'>
                          <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_15')}</Text>
                          &nbsp;
                          <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('login.message_17')}</Text>
                        </Section>
                      </ShapeContainer>
                    </Grid.Column>
                  </Grid>
                  <Section justify='center' paddingTop='45'>
                    <Button
                      outlineBlue
                      name={getLanguage('field.buttonToLogin').toUpperCase()}
                      width={VARIABLES.BUTTON.WIDTHS.W_165}
                      onClick={this.login.onClickBackToLogin}
                    />
                  </Section>
                </React.Fragment>
              }
              {/* RL-707 > Verify with Phone Number */}
              {status === statusForgetList[1] &&
                <React.Fragment>
                  <Section justify='center'>
                    <Section width='300'>
                      <Field name='phoneForgetPassword'
                        fluid
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVerticalPhone'
                        label={getLanguage('field.phoneNo')}
                        placeholder={getLanguage('field.phoneNoPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={phoneForgetPassword}
                        selectedPhoneCountry={countryPhoneForgetPassword}
                        onChangePhoneCountry={this.input.onChangePhoneCountry}
                      />
                    </Section>
                  </Section>
                  <Section paddingTop='45'>
                    <Grid justify='center' gutter='15'>
                      <Grid.Column>
                        <Button
                          outlineBlue
                          name={getLanguage('field.buttonBack').toUpperCase()}
                          width={VARIABLES.BUTTON.WIDTHS.W_165}
                          onClick={this.login.goToForgetPasswordMethod}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          blue
                          name={getLanguage('field.buttonSubmit').toUpperCase()}
                          width={VARIABLES.BUTTON.WIDTHS.W_165}
                          onClick={this.login.onClickSubmitForgetPasswordSendOtp}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                </React.Fragment>
              }
              {/* RL-707 > Verify with OTP */}
              {status === statusForgetList[2] &&
                <React.Fragment>
                  <Section justify='center' spacingBottom='45'>
                    <ShapeContainer padding='45' bgColor={VARIABLES.COLORS.BLUE_5} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='10'>
                      <Section spacingBottom='30'>
                        <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_18')} {getLanguage('login.message_19')}</Text>
                        &nbsp;
                        <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>+{`${countryPhoneForgetPassword.value}${phoneForgetPassword}`}</Text>
                      </Section>
                      <Grid justify='center' gutter='25'>
                        <Grid.Column>
                          <Field name='otpInput0'
                            error={isErrorOTP}
                            ui='pin'
                            maxLength={1}
                            onChange={(ev) => this.input.onChangeOtpInput(ev, 0)}
                            value={otpInputList[0]}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Field name='otpInput1'
                            error={isErrorOTP}
                            ui='pin'
                            maxLength={1}
                            onChange={(ev) => this.input.onChangeOtpInput(ev, 1)}
                            value={otpInputList[1]}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Field name='otpInput2'
                            error={isErrorOTP}
                            ui='pin'
                            maxLength={1}
                            onChange={(ev) => this.input.onChangeOtpInput(ev, 2)}
                            value={otpInputList[2]}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Field name='otpInput3'
                            error={isErrorOTP}
                            ui='pin'
                            maxLength={1}
                            onChange={(ev) => this.input.onChangeOtpInput(ev, 3)}
                            value={otpInputList[3]}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Field name='otpInput4'
                            error={isErrorOTP}
                            ui='pin'
                            maxLength={1}
                            onChange={(ev) => this.input.onChangeOtpInput(ev, 4)}
                            value={otpInputList[4]}
                          />
                        </Grid.Column>
                      </Grid>
                    </ShapeContainer>
                  </Section>
                  <Section spacingBottom='45'>
                    <Grid justify='center' gutter='15'>
                      <Grid.Column>
                        <Button
                          outlineBlue
                          name={getLanguage('login.message_20').toUpperCase() + ' ' + getLanguage('login.message_18').toUpperCase()}
                          width={VARIABLES.BUTTON.WIDTHS.W_165}
                          onClick={this.login.onClickForgetPasswordResendOtp}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          blue
                          name={getLanguage('field.buttonSubmit').toUpperCase()}
                          width={VARIABLES.BUTTON.WIDTHS.W_165}
                          onClick={this.login.onClickSubmitVerifyPhoneNumber}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section justify='center'>
                    <Section width='auto' onClick={this.login.onClickBackToLogin}>
                      <Grid align='center' gutter='10'>
                        <Grid.Column>
                          <Section paddingBottom='3'><img src={ICONS['ic-arrow-back-blue.svg']} alt='Icon' /></Section>
                        </Grid.Column>
                        <Grid.Column><Text medium14 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.buttonToLogin').toUpperCase()}</Text></Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                </React.Fragment>
              }
              {/* RL-707 > Verify with Questions */}
              {status === statusForgetList[3] &&
                <React.Fragment>
                  <Section paddingHorizontal='45' spacingBottom='45'>
                    <Section spacingBottom='30'>
                      <Field name='questionInput1'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_21')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput1}
                      />
                    </Section>
                    <Section spacingBottom='30'>
                      <Field name='questionInput2'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_22')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput2}
                      />
                    </Section>
                    <Section>
                      <Field name='questionInput3'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('login.message_23')}
                        placeholder={getLanguage('field.questionPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={questionInput3}
                      />
                    </Section>
                  </Section>
                  <Grid justify='center' gutter='15'>
                    <Grid.Column>
                      <Button
                        outlineBlue
                        name={getLanguage('field.buttonBack').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.goToForgetPasswordMethod}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        name={getLanguage('field.buttonSubmit').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.onClickSubmitVerifyQuestion}
                      />
                    </Grid.Column>
                  </Grid>
                </React.Fragment>
              }
              {/* RL-707 > Reset Password */}
              {status === statusForgetList[4] &&
                <React.Fragment>
                  <Section paddingHorizontal='45' spacingBottom='45'>
                    <Section spacingBottom='15'>
                      <Field name='resetPassword'
                        fluid
                        labelRequired
                        enableFieldIconAction
                        iconRight
                        error={false}
                        ui='lineLabelVertical'
                        inputTextType={isShowResetPassword ? undefined : 'password'}
                        label={getLanguage('field.newPassword')}
                        placeholder={getLanguage('field.newPasswordPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={resetPassword}
                        fieldIcon={
                          <React.Fragment>
                            {isShowResetPassword ?
                              <Section onClick={this.input.toggleIsShowResetPassword}>
                                <img src={ICONS['ic-eye-password-gray.svg']} alt='Icon' />
                              </Section>
                              :
                              <Section onClick={this.input.toggleIsShowResetPassword}>
                                <img src={ICONS['ic-eye-password-close-gray.svg']} alt='Icon' />
                              </Section>
                            }
                          </React.Fragment>
                        }
                      />
                      <Section justify='flex-end' paddingTop='2'>
                        <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{getLanguage('login.message_24')}</Text>
                      </Section>
                    </Section>
                    <Section>
                      <Field name='confirmResetPassword'
                        fluid
                        labelRequired
                        enableFieldIconAction
                        iconRight
                        error={false}
                        ui='lineLabelVertical'
                        inputTextType={isShowConfirmResetPassword ? undefined : 'password'}
                        label={getLanguage('field.confirmPassword')}
                        placeholder={getLanguage('field.confirmPasswordPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={confirmResetPassword}
                        fieldIcon={
                          <React.Fragment>
                            {isShowConfirmResetPassword ?
                              <Section onClick={this.input.toggleIsShowConfirmResetPassword}>
                                <img src={ICONS['ic-eye-password-gray.svg']} alt='Icon' />
                              </Section>
                              :
                              <Section onClick={this.input.toggleIsShowConfirmResetPassword}>
                                <img src={ICONS['ic-eye-password-close-gray.svg']} alt='Icon' />
                              </Section>
                            }
                          </React.Fragment>
                        }
                      />
                    </Section>
                  </Section>
                  <Grid justify='center' gutter='15'>
                    <Grid.Column>
                      <Button
                        outlineBlue
                        name={getLanguage('field.buttonToLogin').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.onClickBackToLogin}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        name={getLanguage('field.buttonSave').toUpperCase()}
                        width={VARIABLES.BUTTON.WIDTHS.W_165}
                        onClick={this.login.onClickSubmitResetPassword}
                      />
                    </Grid.Column>
                  </Grid>
                  {/* <Section paddingHorizontal='45'>
                    <Section
                      justify='center'
                      spacingBottom='45'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('login.message_4')}
                      </Text>
                    </Section>
                    <Section spacingBottom='25'>
                      <Field name='emailForgetPassword'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVertical'
                        label={getLanguage('field.email')}
                        placeholder={getLanguage('field.emailPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={emailForgetPassword}
                      />
                    </Section>
                    <Section spacingBottom='45'>
                      <Field name='phoneForgetPassword'
                        fluid
                        labelRequired
                        error={false} // This props control error validation
                        focus={false} // This props control onBlur state for change label and field border color
                        ui='lineLabelVerticalPhone'
                        label={getLanguage('field.phoneNo')}
                        placeholder={getLanguage('field.phoneNoPlaceholder')}
                        onChange={this.input.onChangeInput}
                        value={phoneForgetPassword}
                        selectedPhoneCountry={countryPhoneForgetPassword}
                        onChangePhoneCountry={this.input.onChangePhoneCountry}
                      />
                    </Section>
                    <Section>
                      <Grid
                        gutter='20'
                        justify='flex-end'
                      >
                        <Grid.Column>
                          <Button
                            gray
                            name={getLanguage('field.buttonBack').toUpperCase()}
                            width={VARIABLES.BUTTON.WIDTHS.W_165}
                            onClick={this.login.onClickBackToLogin}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Button
                            blue
                            name={getLanguage('field.buttonSend').toUpperCase()}
                            width={VARIABLES.BUTTON.WIDTHS.W_165}
                            onClick={this.login.onClickSendForgetPassword}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                 */}
                </React.Fragment>
              }
              {/* RL-707 > Reset Password Success */}
              {status === statusForgetList[5] &&
                <React.Fragment>
                  <ShapeContainer fluid padding='45' bgColor={VARIABLES.COLORS.BLUE_5} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='10'>
                    <Section justify='center' spacingBottom='30'>
                      <img src={ICONS['ic-status-checked-blue.svg']} alt='Icon' />
                    </Section>
                    <Section justify='center'>
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_26')}</Text>
                    </Section>
                  </ShapeContainer>
                  <Section justify='center' paddingTop='45'>
                    <Button
                      outlineBlue
                      name={getLanguage('field.buttonToLogin').toUpperCase()}
                      width={VARIABLES.BUTTON.WIDTHS.W_165}
                      onClick={this.login.onClickBackToLogin}
                    />
                  </Section>
                </React.Fragment>
              }
            </React.Fragment>
          </ShapeContainer>
          <Section
            versionRight
            ui='versionNumber'
          >
            {TEXT_VERSION}
          </Section>
        </Container>
      </Container>
    )
  }
}
